import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import {
  makeStyles,
  useMediaQuery,
  Grid,
  Typography,
  Modal,
} from "@material-ui/core"
import Checkbox from '@material-ui/core/Checkbox';

import Pay from "../../button/pay"

import { OrderingStateContext } from "../context"

import Star from "../../../../static/svg/star.svg"
import { navigate } from "gatsby"

import Mokka from "../../../../static/svg/mokka.svg"
import MokkaInfo from "../../../../static/svg/mokkaInfo.svg"
import MokkaCross from "../../../../static/svg/mokkaCross.svg"
import MokkaIframeRegistration from "../../mokkaIframeRegistration"
import MokkaIframePay from "../../mokkaIframePay"
import LoadingModal from "../../loadingModal/loadingModal"
import { withPrefix } from "gatsby-link"
import axios from "axios"
import dataLayerHandler from "../../yandexMetrica/dataLayerHandler"

const useStyle = makeStyles(theme => ({
  wrapper: {
    position: "sticky",
    top: 28,
    background: theme.palette.background.secondary,
    touchAction: "none",

    padding: "3.12vw 2.03vw",
    borderRadius: "1.56vw 0 0 1.56vw",
    "@media(min-width: 1280px)": {
      padding: "40px 26px",
      borderRadius: "20px 0 0 20px",
    },
    // "@media(max-width: 1025px)": {
    //   padding: "5.03vw 2.87vw 0.95vw",
    //   borderRadius: "2.39vw 2.39vw 0 0 ",
    //   boxShadow: "0px 0px 2.39vw rgba(0, 0, 0, 0.25)",
    // },
    "@media(max-width: 1025px)": {
      position: "unset",
      background: "transparent",

      padding: 0,
      borderRadius: 0,
    },
  },
  discountWrapper: {
    marginTop: "0.93vw",
    "@media(min-width: 1280px)": {
      marginTop: "12px",
    },
    "@media(max-width: 1025px)": {
      marginTop: "0.95vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "1.93vw",
    },
  },
  totalPriceWrapper: {
    marginTop: "1.56vw",
    "@media(min-width: 1280px)": {
      marginTop: "20px",
    },
    "@media(max-width: 1025px)": {
      marginTop: "2.99vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "6.03vw",
      marginBottom: "6.03vw",
    },
  },
  normalText: {
    fontWeight: 400,
    lineHeight: 1.21,

    fontSize: "1.87vw",
    "@media(min-width: 1280px)": {
      fontSize: "24px",
    },
    "@media(max-width: 1025px)": {
      fontSize: "2.15vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "4.34vw",
    },
  },
  boldText: {
    fontWeight: 700,
    lineHeight: 1.21,

    fontSize: "2.81vw",
    "@media(min-width: 1280px)": {
      fontSize: "36px",
    },
    "@media(max-width: 1025px)": {
      fontWeight: 900,
      fontSize: "2.99vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "6.03vw",
    },
  },
  errorWrapper: {
    marginTop: "1.56vw",
    "@media(min-width: 1280px)": {
      marginTop: "20px",
    },
    "@media(max-width: 1025px)": {
      marginTop: "2.99vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "6.03vw",
    },
  },
  error: {
    display: "flex",
    fontWeight: 400,
    lineHeight: 1.21,
    color: "#FF5B5B",

    fontSize: "1.09vw",
    "@media(min-width: 1280px)": {
      fontSize: "14px",
    },
    "@media(max-width: 1025px)": {
      fontSize: "1.67vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "3.38vw",
    },
  },
  icon: {
    display: "flex",

    width: "0.78vw",
    height: "0.78vw",
    marginRight: "0.23vw",
    "@media(min-width: 1280px)": {
      width: "10px",
      height: "10px",
      marginRight: "3px",
    },
    "@media(max-width: 1025px)": {
      width: "1.19vw",
      height: "1.19vw",
      marginRight: "0.35vw",
    },
    "@media(max-width: 767px)": {
      width: "2.41vw",
      height: "2.41vw",
      marginRight: "0.72vw",
    },
  },
  goRegistration: {
    width: "100%",

    marginTop: "1.56vw",
    height: "3.9vw",
    "@media(min-width: 1280px)": {
      marginTop: "20px",
      height: "50px",
    },
    "@media(max-width: 1025px)": {
      position: "fixed",
      left: "50%",
      transform: "translateX(-50%)",
      bottom: 70,
      zIndex: 99,

      width: "98.08vw",
      height: "5.99vw",
    },
    "@media(max-width: 767px)": {
      width: "97.58vw",
      height: "12.07vw",
    },
  },
  titleCreditAndDelivery: {
    fontWeight: 700,
    lineHeight: 1.21,
    marginTop: "3.12vw",
    fontSize: "1.32vw",
    "@media(min-width: 1280px)": {
      marginTop: "40px",
      fontSize: "17px",
    },
    "@media(max-width: 1025px)": {
      marginTop: "4.79vw",
      fontSize: "2.03vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "9.66vw",
      fontSize: "4.1vw",
    },
  },
  textCredit: {
    cursor: "pointer",

    fontWeight: 400,
    lineHeight: 1.21,
    fontSize: "1.01vw",
    marginTop: "0.62vw",
    "@media(min-width: 1280px)": {
      fontSize: "13px",
      marginTop: "8px",
    },
    "@media(max-width: 1025px)": {
      fontSize: "1.67vw",
      marginTop: "0.95vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "3vw",
      marginTop: "1.93vw",
    },
    "& span": {
      fontWeight: 600,
    },
  },
  variantDevilery: {
    fontWeight: 700,
    fontSize: "1.09vw",
    lineHeight: 1.21,
    marginTop: "0.62vw",
    "@media(min-width: 1280px)": {
      fontSize: "14px",
      marginTop: "8px",
    },
    "@media(max-width: 1025px)": {
      fontSize: "1.67vw",
      marginTop: "0.95vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "3.38vw",
      marginTop: "1.93vw",
    },
    "& span": {
      fontWeight: 400,
      color: "#000000",
      "-webkit-text-fill-color": "#000000",
    },
  },
  rassrochkaSpan: {
    marginLeft: "0.625vw",
    "@media(min-width: 1280px)": {
      marginLeft: "8px",
    },
    "@media(max-width: 1025px)": {
      marginLeft: "0.95vw",
    },
    "@media(max-width: 767px)": {
      marginLeft: "1.93vw",
    },
  },
  mokka: {
    display: "inline-block",

    margin: "0 0.625vw",
    height: "1.32vw",
    "@media(min-width: 1280px)": {
      margin: "0 8px",
      height: "17px",
    },
    "@media(max-width: 1025px)": {
      margin: "0 0.95vw",
      height: "2.03vw",
    },
    "@media(max-width: 767px)": {
      margin: "0 1.93vw",
      height: "3.62vw",
    },
  },
  mokkaInfo: {
    display: "inline-block",
    cursor: "pointer",

    marginLeft: "0.31vw",
    height: "1.32vw",
    width: "1.32vw",
    "@media(min-width: 1280px)": {
      marginLeft: "4px",
      height: "17px",
      width: "17px",
    },
    "@media(max-width: 1025px)": {
      marginLeft: "0.47vw",
      height: "2.03vw",
      width: "2.03vw",
    },
    "@media(max-width: 767px)": {
      marginLeft: "0.96vw",
      height: "4.1vw",
      width: "4.1vw",
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mokkaBaner: {
    position: "relative",
    background: "center / cover no-repeat url('/svg/mokkaBaner.png')",

    width: "58.12vw",
    height: "37.18vw",
    "@media(min-width: 1280px)": {
      width: 744,
      height: 476,
    },
    "@media(max-width: 1025px)": {
      width: "72.58vw",
      height: "46.43vw",
    },
    "@media(max-width: 767px)": {
      width: "97vw",
      height: "62.05vw",
    },
  },
  mokkaCross: {
    padding: 0,
    background: "transparent",
    minHeight: 0,
    minWidth: 0,
    border: "none",
    outline: "none",
    cursor: "pointer",

    position: "absolute",
    top: "7.77%",
    right: "3.76%",

    width: "3.225%",
    height: "5.042%",
  },
  couponInput: {
    width: "100%",

    background: "transparent",
    border: `1px solid #D6D5DF`,
    outline: "none",

    fontWeight: 400,
    lineHeight: 1,

    padding: "1.21vw 0.93vw",
    borderRadius: "0.46vw",
    fontSize: "1.09vw",
    "@media(min-width: 1280px)": {
      padding: "15.5px 12px",
      borderRadius: 6,
      fontSize: 14,
    },
    "@media(max-width: 1025px)": {
      padding: "1.85vw 1.43vw",
      borderRadius: "0.71vw",
      fontSize: "1.67vw",
    },
    "@media(max-width: 767px)": {
      padding: "3.74vw 2.89vw",
      borderRadius: "1.44vw",
      fontSize: "3.38vw",
    },
    "&:focus": {
      "&:placeholder": {
        color: "transparent",
      },
    },
  },
  couponWrapper: {
    position: "relative",
    marginTop: "1.09vw",
    "@media(min-width: 1280px)": {
      marginTop: 14,
    },
    "@media(max-width: 1025px)": {
      marginTop: "1.67vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "3.38vw",
    },
  },
  couponButton: {
    color: theme.palette.color.mainContrast,
    background: theme.palette.background.accent,
    position: "absolute",
    height: "100%",
    right: 0,
    bottom: 0,
    borderLeft: `1px solid ${theme.palette.color.accentSecondary}`,
    borderTopRightRadius: "0.46vw",
    borderBottomRightRadius: "0.46vw",
    padding: "0 1.25vw",
    fontSize: "1.09vw",
    "@media(min-width: 1280px)": {
      padding: "0 16px",
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
      fontSize: 14,
    },
    "@media(max-width: 1025px)": {
      padding: "0 1.56vw",
      borderTopRightRadius: "0.71vw",
      borderBottomRightRadius: "0.71vw",
      fontSize: "1.67vw",
    },
    "@media(max-width: 767px)": {
      padding: "0 2.08vw",
      borderTopRightRadius: "1.44vw",
      borderBottomRightRadius: "1.44vw",
      fontSize: "3.38vw",
    },
  },
  couponInputFocus: {
    border: `1px solid ${theme.palette.color.accentSecondary}`,
    borderRight: "unset",
    "&::placeholder": {
      color: "transparent",
    },
  },
  couponYesMessage: {
    color: "#20CA1D",
  },
  couponNoMessage: {
    color: "#FF5B5B",
  },
}))

/**
 * Блок цены на странице оформления заказа
 * @module src/components/orderingPage/priceBlock
 * @param {Object} props - объект свойств компонента React
 * @param {Object[]} props.products - массив объектов продуктов полученых из prismic
 */
export default function PriceBlock({ products, prismicVariantPay, cities }) {
  const classes = useStyle()
  const mobile = useMediaQuery("(max-width: 1025px)")

  // Варианты оплаты (для тестирования):
  // const prismicPayVariants = {
  //   'online': true,
  //   'upon_receipt': true,
  //   'tinkoff_credit': true,
  //   'vsegda_da_credit': true,
  //   'poscredit_credit': true,
  //   'mokka_payment': true,
  //   'payment_for_legal_entities': true
  // }

  // Варианты оплаты:
  let prismicPayVariants = {}
  prismicVariantPay.forEach(item => {
    let payName
    switch (item.pay_name.text) {
      case "Тинькофф онлайн оплата":
        payName = "online"
        break
      case "Тинькофф Кредит":
        payName = "tinkoff_credit"
        break
      case "Мокка рассрочка":
        payName = "mokka_payment"
        break
      case "При получении":
        payName = "upon_receipt"
        break
      case "Всегда.да":
        payName = "vsegda_da_credit"
        break
      case "Банковский перевод для юр лиц":
        payName = "payment_for_legal_entities"
        break
    }
    prismicPayVariants[payName] = item.visibility
  })

  const [showMokkaInfo, setShowMokkaInfo] = React.useState(false)
  const [showMokkaIframe, setShowMokkaIframe] = React.useState(false)
  const [mokkaFormUrl, setMokkaFormUrl] = React.useState(false)

  const orderingState = React.useContext(OrderingStateContext)

  const validData = orderingState.validationAll(
    orderingState.variantPay === "перевод"
  )

  const order = JSON.parse(localStorage.getItem("order"))

  const [sendQuery, setSendQuery] = React.useState(false)

  const [loadingOpen, setLoadingOpen] = React.useState(false)

  const [couponInputCode, setCouponInputCode] = React.useState("")
  const [couponInputFocus, setcouponInputFocus] = React.useState(false)
  const [rightCoupon, setRightCoupon] = React.useState(null)
  const [couponType, setCouponType] = React.useState(null)
  const [couponCode, setCouponCode] = React.useState(null)
  const [couponDiscount, setCouponDiscount] = React.useState(null)
  const [couponMessage, setCouponMessage] = React.useState(null)
  const [openModal, setOpenModal] = React.useState(false)
  const accessID = "115028"

  //Pos credit variables
  let orderNumber = ""
  let phone = ""
  let productsList = []

  const posCredit = () => {
    const script = document.createElement("script")

    script.type = "text/javascript"
    script.async = true
    script.innerHTML = `
        poscreditServices('creditProcess', ${accessID}, { order: '${orderNumber}', products: ${productsList}, phone: '${phone}', debug: false }, function(result){
          if(result.success === false){
              alert('Произошла ошибка при попытке оформить кредит. Попробуйте позднее...');
          }
        })
        
        function poscreditSaveProfile(id) {
           localStorage.setItem("posID", id)
        }
      `

    document.querySelector("#poscreditTest").appendChild(script)

    return () => {
      document.querySelector("#poscreditTest").removeChild(script)
    }
  }

  function payOrder() {
    if (sendQuery) return

    if (validData) {
      setSendQuery(true)
      setLoadingOpen(!loadingOpen)
      const apiURL = "https://admin.krypton.ru/api/order/create/"

      const headers = new Headers()
      headers.append("Content-Type", "application/json")

      const orderItems = []
      const repairItems = []
      order.allProductsJson.forEach(product => {
        if (product?.repair) {
          repairItems.push({
            repair_category: product?.repair_category,
            services: product?.repair_services,
            quantity: product?.quantity,
          })
        } else {
          orderItems.push({
            quantity: product?.quantity,
            prismic_uid: product?.product_uid,
            seller: product?.seller,
          })
        }
      })

      let coupon
      if (couponCode) {
        coupon = {
          code: couponCode,
          discount: couponDiscount,
          type: couponType,
        }
      } else {
        coupon = ""
      }

      const body = JSON.stringify({
        name: orderingState.name,
        phone: orderingState.phone,
        email: orderingState.email,
        variantPay: orderingState.variantPay,
        vsegdaCreditCode: orderingState.vsegdaCode,
        inn: orderingState.variantPay === "перевод" ? orderingState.inn : "",
        coupon: coupon,
        city: localStorage.getItem("city"),
        utm: JSON.parse(localStorage.getItem("UTM")),

        order: orderItems.length
          ? {
              city: orderingState.city,
              street: orderingState.street,
              house: orderingState.house,
              apartment: orderingState.apartment,
              variantDelivery: orderingState.variantDelivery,
              date: orderingState.date
                .slice(0, 10)
                .split("/")
                .reverse()
                .join("-"),
              time_from: orderingState.time.slice(0, 5),
              time_to: orderingState.time.slice(-5),

              items: orderItems,
            }
          : null,

        repair: repairItems.length
          ? {
              city: orderingState.repairCity,
              street: orderingState.repairStreet,
              house: orderingState.repairHouse,
              apartment: orderingState.repairApartment,
              variantDelivery: orderingState.repairVariantDelivery,
              date: orderingState.repairDate
                .slice(0, 10)
                .split("/")
                .reverse()
                .join("-"),
              time_from: orderingState.repairTime.slice(0, 5),
              time_to: orderingState.repairTime.slice(-5),

              items: repairItems,
            }
          : null,
      })

      localStorage.setItem("ordering_state", JSON.stringify(orderingState))

      const init = {
        method: "POST",
        headers,
        body,
      }

      fetch(apiURL, init)
        .then(res => res.json())
        .then(res => {
          localStorage.setItem("order_number", JSON.stringify(res.order_number))
          orderNumber = JSON.stringify(res.order_number)
          dataLayerHandler("purchase", {
            products: [...order.allProductsJson],
            orderNum: res.order_number,
          })

          if (res.error) {
            localStorage.setItem(
              "order_number",
              JSON.stringify(res.order_number)
            )
            navigate("/order/", { state: { error: res.status } })
            return
          }

          if (
            orderingState.variantPay === "при получении" ||
            orderingState.variantPay === "перевод"
          ) {
            navigate("/order/")
          }
          if (orderingState.variantPay === "в рассрочку") {
            setMokkaFormUrl(res.payment_data.url)
          }
          if (
            orderingState.variantPay === "онлайн" ||
            orderingState.variantPay === "в кредит" ||
            orderingState.variantPay === "всегда да" ||
            orderingState.variantPay === "денум"
          ) {
            new Promise(res => {
              setTimeout(res, 1000)
            })
            window.location.href = res.payment_data.url
          }

          orderNumber = JSON.stringify(res.order_number)
          productsList = JSON.stringify(res.products_list)
          phone = JSON.stringify(res.phone)
          if (orderingState.variantPay === "пос кредит") {
            new Promise(res => setTimeout(res, 1000))
            setLoadingOpen(false)
            setOpenModal(true)
            setTimeout(() => posCredit(), 1001)
          }
        })
        .catch(error => {
          setSendQuery(false)
          console.log(error)
        })
    }
  }

  const initCity = localStorage.getItem("city")
  const checkPickupCity = () => {
    if (initCity !== "") {
      var cityIndex = cities.findIndex(item => item.city === initCity)
      return cities[cityIndex].pickup
    }
  }
  // варианты доставки
  const devilery =
    products[0].data.delivery?.document?.data.variants.filter(item => {
      if (!checkPickupCity()) {
        if (item.name === "Самовывоз") {
          return false
        }
      }
      return true
    }) ?? []

  // данные по кредиту и рассрочке
  const credit = products[0]?.data.credit?.document?.data ?? null

  const ps = +credit?.percent.replace(",", ".") / 12 / 100
  const creditValue =
    credit?.percent && credit?.months_1
      ? order.price * (ps / (1 - Math.pow(1 + ps, -credit?.months_1)))
      : null

  // преобразуем цену
  function priceMod(value) {
    let price = "" + value
    let length = price.length
    for (let i = 1; i < length; i++) {
      if (i % 3 === 0) {
        price = price.slice(0, length - i) + " " + price.slice(length - i)
      }
    }
    return price
  }

  function switchShowMokkaInfo(e) {
    e.preventDefault()
    setShowMokkaInfo(!showMokkaInfo)
  }

  function switchShowMokkaIframe(e) {
    if (e.defaultPrevented) return
    setShowMokkaIframe(!showMokkaIframe)
  }

  const checkCoupon = () => {
    const apiURL = "https://admin.krypton.ru/api/coupon/check"
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    const body = JSON.stringify({
      coupon_code: couponInputCode,
    })

    const init = {
      method: "POST",
      headers,
      body,
    }

    fetch(apiURL, init)
      .then(res => res.json())
      .then(res => {
        if (res.coupon) {
          setRightCoupon("yes")
          setCouponCode(res.coupon.fields.code)
          setCouponDiscount(res.coupon.fields.discount)
          setCouponType(res.coupon.fields.type)
          setCouponMessage(res.message)
        } else {
          setRightCoupon("no")
          setCouponCode(false)
          setCouponDiscount(false)
          setCouponType(false)
          setCouponMessage(res.message)
        }
      })
  }

  useEffect(() => {
    const posCreditStatusHandler = e => {
      if (e.data) {
        if (
          (typeof e.data === "string" || e.data instanceof String) &&
          /(statusID)/gi.test(e.data)
        ) {
          var posCreditMessage = JSON.parse(e.data)
          if (posCreditMessage.statusID) {
            var order_number = localStorage
              .getItem("order_number")
              .replaceAll('"', "")
            var posApiURL = "https://admin.krypton.ru/api/pos-credit/status"
            const posHeaders = new Headers()
            posHeaders.append("Content-Type", "application/json")
            var posBody = JSON.stringify({
              order_number: order_number,
              status: posCreditMessage.statusID,
            }).replaceAll("\\", "")

            if (sendQuery === false) {
              axios({
                method: "post",
                url: posApiURL,
                data: posBody,
                headers: { "Content-Type": "application/json" },
              })
                .then(function (response) {
                  //handle success
                  if (
                    posCreditMessage.statusID === 6 ||
                    posCreditMessage.statusID === 4
                  ) {
                    setLoadingOpen(true)
                    navigate("/order/")
                    return
                  }
                  window.location.reload()
                })
                .catch(function (response) {
                  //handle error
                  console.log(response)
                })
            }
            setTimeout(() => {
              setOpenModal(false)
            }, 0)
          }
        }
      }
    }
    window.addEventListener("message", e => {
      posCreditStatusHandler(e)
    })

    return window.removeEventListener("message", e => {
      posCreditStatusHandler(e)
    })
  }, [])

  return (
    <div className={classes.wrapper}>
      <Grid container justify="space-between">
        <Typography className={classes.normalText}>Стоимость</Typography>

        <Typography className={classes.normalText}>{`${priceMod(
          order.price - order.sale
        )} ₽`}</Typography>
      </Grid>

      <Grid
        container
        justify="space-between"
        className={classes.discountWrapper}
      >
        <Typography className={classes.normalText}>Скидка</Typography>

        {rightCoupon === "yes" && couponType === "Рублей" ? (
          <Typography
            className={classes.normalText}
            style={{ color: "#FF5B5B" }}
          >
            {`${priceMod(order.sale - couponDiscount)} ₽`}
          </Typography>
        ) : null}
        {rightCoupon === "yes" && couponType === "Процентов" ? (
          <Typography
            className={classes.normalText}
            style={{ color: "#FF5B5B" }}
          >
            {`${priceMod(
              order.sale - Math.round((order.price * couponDiscount) / 100)
            )} ₽`}
          </Typography>
        ) : null}
        {rightCoupon === "no" || !rightCoupon ? (
          <Typography
            className={classes.normalText}
            style={{ color: "#FF5B5B" }}
          >
            {`${priceMod(order.sale)} ₽`}
          </Typography>
        ) : null}
      </Grid>

      {/* --------------------------coupon--------------------------- */}
      {order.price >= 5000 ? (
        <>
          <form
            action="#"
            className={classes.couponWrapper}
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <input
              placeholder="Промокод"
              value={couponInputCode}
              onInput={e => {
                setCouponInputCode(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""))
              }}
              className={
                classes.couponInput +
                " " +
                (couponInputFocus ? classes.couponInputFocus : "")
              }
              onFocus={e => setcouponInputFocus(true)}
              onBlur={e => setcouponInputFocus(false)}
            ></input>
            <button
              type="submit"
              className={classes.couponButton}
              onClick={e => {
                checkCoupon()
              }}
            >
              Применить
            </button>
          </form>
          {rightCoupon === "yes" ? (
            <div
              className={`${classes.error} ${classes.couponYesMessage} ${classes.discountWrapper}`}
            >
              {couponMessage}
            </div>
          ) : null}
          {rightCoupon === "no" ? (
            <div
              className={`${classes.error} ${classes.couponNoMessage} ${classes.discountWrapper}`}
            >
              {couponMessage}
            </div>
          ) : null}
        </>
      ) : null}

      <Grid
        container
        justify="space-between"
        className={classes.discountWrapper}
      >
        <Typography className={classes.normalText}>Доставка</Typography>

        <Typography className={classes.normalText} style={{ color: "#20CA1D" }}>
          Бесплатно
        </Typography>
      </Grid>

      <Grid
        container
        justify="space-between"
        className={classes.totalPriceWrapper}
      >
        <Typography className={classes.boldText}>Итого</Typography>

        {rightCoupon === "yes" && couponType === "Рублей" ? (
          <Typography className={classes.boldText}>
            {`${priceMod(order.price - couponDiscount)} ₽`}
          </Typography>
        ) : null}
        {rightCoupon === "yes" && couponType === "Процентов" ? (
          <Typography className={classes.boldText}>
            {`${priceMod(
              order.price - Math.round((order.price * couponDiscount) / 100)
            )} ₽`}
          </Typography>
        ) : null}
        {rightCoupon === "no" || !rightCoupon ? (
          <Typography className={classes.boldText}>
            {`${priceMod(order.price)} ₽`}
          </Typography>
        ) : null}
      </Grid>

      {validData || mobile ? null : (
        <div className={classes.errorWrapper}>
          <Typography className={classes.error}>
            <i className={classes.icon}>
              <Star />
            </i>
            Некоторые поля заполнены некорректно
          </Typography>
        </div>
      )}

      <div style={{display: 'flex'}}>
        <Checkbox checked={true} variant={'primary'} style={{color: "#681DE1"}}/>
        <div>Даю согласие на обработку своих персональных данных</div>
      </div>

      <div className={classes.goRegistration}>
        {orderingState.variantPay === "онлайн" ? (
          <Helmet>
            <script src="https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js"></script>
          </Helmet>
        ) : null}

        {orderingState.variantPay === "в кредит" ? (
          <Helmet>
            <script src="https://forma.tinkoff.ru/static/onlineScript.js" />
          </Helmet>
        ) : null}

        <Pay text="Подтвердить заказ" products={products} onClick={payOrder} />

        

        <LoadingModal isModalOpen={loadingOpen} />

        <Modal open={mokkaFormUrl} className={classes.modal}>
          <MokkaIframePay url={mokkaFormUrl} />
        </Modal>
      </div>

      {(credit || devilery.length) && !mobile ? (
        <>
          {!prismicPayVariants.tinkoff_credit &&
          !prismicPayVariants.mokka_payment ? null : (
            <>
              {(!prismicPayVariants.tinkoff_credit &&
                prismicPayVariants.mokka_payment &&
                order.price >= 100000) ||
              order.price < 5000 ? null : (
                <Typography className={classes.titleCreditAndDelivery}>
                  Оплата авансом и кредит
                </Typography>
              )}

              {!prismicPayVariants.tinkoff_credit ? null : (
                <Typography
                  hidden={!creditValue}
                  className={classes.textCredit}
                  style={{ cursor: "inherit" }}
                >
                  Кредит в Тинькофф от{" "}
                  <span>{priceMod(Math.trunc(creditValue))} ₽/мес</span>
                </Typography>
              )}

              {!prismicPayVariants.mokka_payment ||
              order.price >= 100000 ||
              order.price < 5000 ? null : (
                <Typography
                  role="button"
                  onClick={switchShowMokkaIframe}
                  hidden={!credit.months_2}
                  className={classes.textCredit}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Оплата авансом
                  <span className={classes.rassrochkaSpan}>
                    от {priceMod(Math.trunc(order.price / credit.months_2))}{" "}
                    ₽/мес
                  </span>
                  <span className={classes.mokka}>
                    <Mokka />
                  </span>
                  |
                  <span
                    role="button"
                    onClick={switchShowMokkaInfo}
                    className={classes.mokkaInfo}
                  >
                    <MokkaInfo aria-label="mokka info" />
                  </span>
                </Typography>
              )}
            </>
          )}
        </>
      ) : null}

      <Modal
        open={showMokkaIframe}
        onClose={() => setShowMokkaIframe(false)}
        className={classes.modal}
      >
        <MokkaIframeRegistration onClose={() => setShowMokkaIframe(false)} />
      </Modal>

      <Modal
        open={showMokkaInfo}
        onClose={() => setShowMokkaInfo(false)}
        className={classes.modal}
      >
        <div className={classes.mokkaBaner}>
          <button
            onClick={() => setShowMokkaInfo(false)}
            className={classes.mokkaCross}
          >
            <MokkaCross />
          </button>
        </div>
      </Modal>

      {devilery.length && !mobile ? (
        <>
          <Typography className={classes.titleCreditAndDelivery}>
            Способ получения
          </Typography>

          {devilery.map(variant => (
            <Typography
              variant="body2"
              key={variant.name}
              className={classes.variantDevilery}
            >
              {variant.name} <span>{variant.description}</span>
            </Typography>
          ))}
        </>
      ) : null}

      <Modal
        className={classes.modal}
        open={openModal}
        disableScrollLock={false}
      >
        <div
          className={classes.container}
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <div
            id="poscreditTest"
            style={{
              overflowY: "scroll",
              height: "100vh",
              paddingRight: "17px",
              boxSizing: "content-box",
            }}
          >
            <Helmet>
              <script
                src={withPrefix("connect.js")}
                charSet="utf-8"
                type="text/javascript"
              ></script>
            </Helmet>
          </div>
        </div>
      </Modal>
    </div>
  )
}
